<template>
  <section class="q-pa-md">
    <h1>
      {{ value.name || $route.params.slug }}
    </h1>
    <q-markdown
      v-if="value.description"
      class="text-body1"
    >{{ value.description }}
    </q-markdown>
    <br>
    <br>
    <h2>
      Assignments
      <q-btn
        color="primary"
        icon="fas fa-sync"
        label="Refresh"
        @click="refreshGroupAssignment"
      />
    </h2>
    <p>
      This section shows routes between two airports that have at least five
      passenger terminal assignments (a.k.a. green jobs) going and coming back.
    </p>
    <p>
      Routes are sorted by their urgency (expiring soonest first), you're
      recommended to start flying from the top of the list.
    </p>
    <route-assignments-list-card
      :loading="loading"
      :value="routeAssignmentsList"
    />
    <br>
    <br>
    <h2>Airports</h2>
    <p>
      <q-icon
        left
        name="fas fa-plane-departure"
      />
      {{ value.name }} has passenger terminals in
      <strong>{{ $_.get(value, 'facilities', []).length }}</strong>
      airports
    </p>
    <template v-if="sortedAirportIds">
      <p class="q-gutter-md q-mt-md">
        <template v-for="airportId of sortedAirportIds">
          <q-btn
            :label="airportId"
            :to="`/${$route.params.locale}/airport/${airportId}`"
            icon="fas fa-fw fa-plane-departure"
          />
        </template>
      </p>
    </template>
  </section>
</template>

<script>
  import { get } from 'lodash'
  import RouteAssignmentsListCard
    from 'pages/_locale/group/RouteAssignmentsListCard'

  const moduleName = 'group'

  export default {
    components: { RouteAssignmentsListCard },
    computed: {
      sortedAirportIds: {
        get () {
          if (
            this.$_.isEmpty(
              this.value.facilities,
            )
          ) {
            return []
          }

          return this.value.facilities.map(
            facility => facility.airportId,
          ).sort()
        },
      },
    },
    methods: {
      async refreshGroup () {
        let response = null
        try {
          response = await this.$api.get(
            `group/_slug/${this.$route.params.slug}`,
          )
        } catch (error) {
          // await this.$router.push(
          //   `/${this.$route.params.locale}/group`,
          // )

          return
        }

        this.value = response.data.data
      },
      async refreshGroupAssignment () {
        this.loading = true

        let response = null
        try {
          response = await this.$api.get(
            `assignment/aggregated`,
            {
              params: {
                'groupSlugs': [
                  this.$route.params.slug,
                ],
              },
            },
          )
        } catch (error) {
          this.loading = false

          return
        }

        this.routeAssignmentsList = response.data.data
        this.loading = false
      },
      async refresh () {
        await Promise.all(
          [
            this.refreshGroup(),
            this.refreshGroupAssignment(),
          ],
        )
      },
    },
    async created () {
      await this.refresh()
    },
    data () {
      return {
        loading: false,
        value: {},
        routeAssignmentsList: [],
      }
    },
    meta () {
      return {
        title: `${get(
          this.value,
          'name',
          'Group',
        )} | Groups`,
      }
    },
  }
</script>
